import React from "react"

import Layout from "../components/layout"

import Header from "../components/header"
import Footer from "../components/footer"

import SEO from "../components/seo"

class PrivacyPolicy extends React.Component {

	componentDidMount() {
		document.getElementsByTagName('html')[0].classList.add('seo-page');
	}

	componentWillUnmount() {
		document.getElementsByTagName('html')[0].className = "";
	}

	render() {
		return (
			<Layout>
				<SEO title="Privacy policy | Writera Limited" />
				<Header />

				<main className="main privacy-policy">
					<h1>Privacy policy</h1>

					<p>Writera Limited (herein referred to as “Writera”, or “we”, “us”, “our”), a legal entity registered and existing under the laws of Cyprus, has created this Privacy Policy in line with our commitment to your privacy on writera.ltd website (the “Website”). The following discloses our personal data (a) collection, (b) processing, and (c) protection practices.</p>
					<p>Users of the Website (herein referred to as “you”, “yours”) shall familiarize with the content of this Policy in order to be aware of our and your mutual rights and obligations.</p>

					<h2>Compliance</h2>
					<p>By accessing this Website, you agree to the terms of the Writera Privacy Policy outlined below. If you do not agree to the terms of this policy, please do not access or use this Website.</p>

					<h2>Collection of information</h2>
					<p>Writera collects information to provide you with a more detailed information regarding businesses of our company. Collection commends:</p>
					<ul>
						<li>when you affirmatively choose to submit information to us (such as your name, e-mail address, company name, title, country) via “Contact Us” form for the purpose of contacting us;</li>
						<li>when we get information from your use of our websites, such as the IP address, browser type, ISP, referring/exit pages, platform type, date/time stamp, number of clicks, domain name and country/state of all visitors to the site.</li>
					</ul>
					<p>Please, note – when you use the Website, we may collect and process information about your actual location (the “Location Information”). We use various technologies and tools to determine location.</p>

					<h2>Cookies</h2>
					<p>We may use cookies on the Website. Cookies record your visit to our Website, pages you have visited, and links you have followed. Please, note that  we will use this information only to get statistical data. And, we may also share this information with third parties for same purpose.</p>
					<p>If you decide to modify cookie settings in order to disable them, you may do so by changing the settings of your browser. However, if you do disable cookies entirely, we would not be able to guarantee that all the features of our Website will run smoothly.</p>

					<h2>Use of information</h2>
					<p>Any information collection by Writera will be done in accordance with this Privacy Policy for the purposes of (a) making better business decisions, (b) supporting activities you would engage in, and (c) providing higher quality solutions and services to our customers. We use your Location Information to tailor your experience within the Website. We also may use any non-personal information we collect to enhance our Website, and your IP address for the Website diagnostics and administration.</p>
					<p>We will not (a) sell, (b) share, or (c) rent this information for commercial purposes to any third party – person or organization.</p>
					<p>We process information collected on our servers in many countries around the world. We may process your information on a server located outside the country where you live and, by using our Website, you do agree to such. In accordance with applicable law, the personal data, within the scope of this Privacy Policy, may be transferred and processed in the United States of America, European Union, or in any other country in which Writera, or its affiliates, subsidiaries, or service providers, have an infrastructure, even if the level of confidentiality data required in such a country is below the level of requirements in force in the European Union, or in any other jurisdictions. By providing personal data to Writera, you agree to such transfer and processing of your personal data worldwide.</p>

					<h2>Information security</h2>
					<p>Writera treats your personal information as confidential information. We protect this information by storing it on secure servers, to which only Writera personnel, or our authorized agents, have access and, also, we encrypt it in order to help prevent access by any unauthorized parties.</p>

					<h2>Links</h2>
					<p>This Website may contain links to various third-party websites. Writera has no control over, and is not responsible for, the content, privacy policies or reliability of such sites.</p>

					<h2>Changes to the Policy</h2>
					<p>Writera reserves the right to modify or amend this Privacy Policy at its own discretion. The changes can be viewed here or elsewhere at this Website. Your continued/further use of this Website will mean you accept those changes.</p>

					<p><em>Published on: February 14, 2020</em></p>

				</main>

				<Footer />
			</Layout>
		)
	}
}

export default PrivacyPolicy
